@import '../../../styles/gifts/sass/basics';
@import '../../../styles/mixins/default-picture';

.bb-hero-strapline-with-decoration {
  overflow: hidden;
  position: relative;

  &__image {
    position: relative;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    :global{
      @include default-picture();
    }

    img {
      aspect-ratio: 1440/500;
    }
  }

  &__stars {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &-first {
      position: absolute;
      top: 32px;
      left: 48px;
      width: 86px;
      height: 86px;
    }

    &-second {
      position: absolute;
      bottom: 88px;
      right: 48px;
      width: 126px;
      height: 126px;
    }

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }

  &__stars-container {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media (min-width: 1440px) {
      position: relative;
    }
  }

  &__copy {
    @media (min-width: $screen-lg-min) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__copy-container {
    display: flex;
    height: 100%;
    align-items: flex-end;

    @media (min-width: $screen-xlg) {
      align-items: center;
    }
  }

  &__copy-text {
    background: $white;
    padding: 16px 0;

    h1, h2 {
      color: $black;
      font-size: 28px;
      font-weight: bold;
      display: inline-block;
      margin: 0;
      line-height: 1.28;

      @media (min-width: $screen-lg-min) {
        line-height: 1;
        font-size: $font-size-h1;
      }
    }

    p {
      color: $black;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0;
      margin-top: 8px;

      @media (min-width: $screen-lg-min) {
        font-size: 18px;
        margin-top: 12px;
      }

      @media (min-width: $screen-xlg) {
        font-size: 20px;
      }
    }

    @media (min-width: $screen-lg-min) {
      max-width: 792px;
      padding: 16px 24px;
      margin-bottom: 16px;
    }

    @media (min-width: $screen-xlg) {
      max-width: 506px;
      //margin-left: -42px;
    }
  }
}
